import React, { Component } from 'react'
import { NotFoundSerSectionStyle } from './NotFoundSerSection.styles'
import SingleService from 'components/SingleService/SingleService'

class NotFoundSerSection extends Component {
  render() {
    const { notfoundSerProps } = this.props.notfoundSerData
    return (
      <NotFoundSerSectionStyle>
        <div className="container">
          <div className="notfound-service-listing">
            {notfoundSerProps.serviceContents.map((serviceContent, i) => {
              return <SingleService key={i} {...serviceContent} />
            })}
          </div>
        </div>
      </NotFoundSerSectionStyle>
    )
  }
}

export default NotFoundSerSection
