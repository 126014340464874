import React from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import styled from 'styled-components'
import SEO from 'components/Seo'
import { NotFoundProps } from 'components/Props/notfound'
import { graphql } from 'gatsby'
import NotFoundSerSection from 'components/NotFoundSerSection/NotFoundSerSection'

export const NotFoundPageWrap = styled.div`
  .banner {
    .banner-content {
      max-width: 880px;
      p {
        max-width: 660px;
        margin: 0 auto;
        margin-bottom: 60px;
      }
    }
  }
`

const NotFoundPage = props => {
  const { location } = props
  return (
    <Layout mainClass="not-found-page" location={location}>
      <SEO
        title="404: Not found"
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
        ]}
      />
      <NotFoundPageWrap>
        <Banner bannerData={NotFoundProps} showBannerImage={true} {...props} />
        <NotFoundSerSection notfoundSerData={NotFoundProps} />
      </NotFoundPageWrap>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/404-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 547) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
