import styled from 'styled-components'
import { lg, xl, sm } from 'config/variables'

export const NotFoundSerSectionStyle = styled.section`
  margin: 0 0 81px;
  ${xl(`
    margin: 0 0 60px;
  `)}
  ${lg(`
    margin: 0 0 60px;
  `)}
  .notfound-service-listing {
    display: flex;
    justify-content: space-between;
    ${xl(`
         overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding: 20px 0;
    `)}
    ${lg(`
         overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding: 20px 0;
    `)}
    .service-single {
      max-width: 210px;
      min-width: 210px;
      flex-wrap: wrap;
      justify-content: center;
      align-content: flex-start;
      padding: 22px 19px;
      ${xl(`
        margin-right: 20px;   
        `)}
      ${lg(`
        margin-right: 20px;   
        `)}
      ${sm(`
        flex-direction: row;
        `)}
      .service-img-blk {
        margin: 0 0 19px;
        width: 70px;
        height: 70px;
        img {
          width: 100%;
        }
      }
      .service-content-blk {
        flex: 100%;
        text-align: center;
      }
    }
  }
`
