import { images } from 'config/images'

export const NotFoundProps = {
  bannerProps: {
    bannerHeading: 'Oops! Page ',
    bannerHeadingSpan: 'not found',
    bannerContent:
      "We’re sorry we've lost our spark or this page does not exist. Try the links below to start your experience again.",
  },
  notfoundSerProps: {
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Our services',
        serviceParagraph: 'Navigate through our service offerings.',
        serviceIcon: images.eiconservice,
        serviceAlt: 'Service',
        serviceClass: 'very-light-pink',
        serviceLink: 'services/software-development/',
      },
      {
        id: '2',
        serviceHeading: 'About us',
        serviceParagraph: 'We are Simform. Know us better.',
        serviceIcon: images.eiconabout,
        serviceAlt: 'About',
        serviceClass: 'very-light-pink-three',
        serviceLink: '/about-us/',
      },
      {
        id: '3',
        serviceHeading: 'Careers',
        serviceParagraph: 'Be the part of a team of disruptors. ',
        serviceIcon: images.eiconcareer,
        serviceAlt: 'Careers',
        serviceClass: 'pale-grey',
        serviceLink: '/careers/',
      },
      {
        id: '4',
        serviceHeading: 'Blog',
        serviceParagraph: 'Mobile & Software Dev. Resources.',
        serviceIcon: images.eiconblog,
        serviceAlt: 'Blog',
        serviceClass: 'ice-blue',
        serviceLink: '/blog/',
      },
      {
        id: '5',
        serviceHeading: 'Contact',
        serviceParagraph: 'Get in touch. We’d love to help.',
        serviceIcon: images.eiconcontact,
        serviceAlt: 'Contact',
        serviceClass: 'off-white',
        serviceLink: '/contact/',
      },
    ],
  },
}
